import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Hidden from "@material-ui/core/Hidden"
import styled from "styled-components"
import "./top.css"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import { Box } from "@material-ui/core"
import WrapTypo from "../components/WrapTypo"
import Grid from "@material-ui/core/Grid"
import { navigate } from "gatsby"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "headerArt.png" }) {
        childImageSharp {
          fluid(maxWidth: 134) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactButton: file(relativePath: { eq: "contactButton.png" }) {
        childImageSharp {
          fluid(maxWidth: 98) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spMenu: file(relativePath: { eq: "spMenu.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      instagram: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pcContact: file(relativePath: { eq: "contactHeader.png" }) {
        childImageSharp {
          fluid(maxWidth: 98) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spContact: file(relativePath: { eq: "spContactHeader.png" }) {
        childImageSharp {
          fluid(maxWidth: 86) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      batu: file(relativePath: { eq: "batu.png" }) {
        childImageSharp {
          fluid(maxWidth: 20) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Header = styled.div`
    background-color: #1a1a1a;
    color: #e8ded3;
  `

  const MenuFont = styled(WrapTypo)`
    font-family: "Brooklyn Samuels";
    color: #e8ded3;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 200%;
    font-size: 22px;
  `

  const LogoPosition = styled.div`
    position: absolute;
    width: 66.74px;
    height: 24.25px;
    @media screen and (max-width: 600px) {
      left: 41.07%;
    }
    @media screen and (min-width: 600px) and (max-width: 1280px) {
      left: 45.07%;
    }
  `

  const Font = styled(Button)`
    font-family: "Brooklyn Samuels";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: center;
  `

  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Box style={{ position: "fixed", zIndex: "999", width: "100%" }}>
        <Box
          style={
            isOpen == true
              ? { display: "none" }
              : {
                  backgroundColor: "#1a1a1a",
                  color: "#e8ded3",
                  position: "relative",
                }
          }
        >
          <Hidden mdDown>
            <div style={{ height: "90px" }}>
              <Grid item xs={3}>
                <Box
                  style={{
                    padding: "21px 0 0 79px",
                  }}
                >
                  <Img
                    style={{ maxWidth: "134px" }}
                    fluid={data.placeholderImage.childImageSharp.fluid}
                  />
                </Box>
              </Grid>
              <div
                style={{
                  position: "absolute",
                  left: "26.25%",
                  right: "7.22%",
                  top: "36.61%",
                  bottom: "36.5%",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Box width="67%">
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Link to="/#about">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            ABOUT
                          </WrapTypo>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Box textAlign="center">
                          <Link to="/#art">
                            <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                              ART&amp;ROOMS
                              <br />
                              （PRICE）
                            </WrapTypo>
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Link to="/#floor">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            FLOOR
                          </WrapTypo>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to="/#service">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            SERVICE
                          </WrapTypo>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to="/#event">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            EVENT
                          </WrapTypo>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to="/#news">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            NEWS
                          </WrapTypo>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to="/#access">
                          <WrapTypo ff="Brooklyn" mt="0" color="#E8DED3">
                            ACCESS
                          </WrapTypo>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box width="23%">
                    <Box width="50%" margin="auto" padding="5px 0 0 ">
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <div
                            style={{
                              width: "14.14px",
                              height: "11.51px",
                              marginRight: "14.19px",
                            }}
                          >
                            <Link
                              rel="noreferrer"
                              target="_blank"
                              to="https://twitter.com/A_YOTSUYA"
                            >
                              <Img fluid={data.twitter.childImageSharp.fluid} />
                            </Link>
                          </div>
                        </Grid>
                        <Grid item>
                          <div
                            style={{
                              width: "6.67px",
                              height: "12.4px",
                              marginRight: "16.07px",
                            }}
                          >
                            <Link
                              rel="noreferrer"
                              target="_blank"
                              to="https://www.facebook.com/a.yotsuya/"
                            >
                              <Img
                                fluid={data.facebook.childImageSharp.fluid}
                              />
                            </Link>
                          </div>
                        </Grid>
                        <Grid item>
                          <div style={{ width: "12.22px", height: "12.17px" }}>
                            <Link
                              rel="noreferrer"
                              target="_blank"
                              to="https://www.instagram.com/a_yotsuya/"
                            >
                              <Img
                                fluid={data.instagram.childImageSharp.fluid}
                              />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Grid alignItems="flex-start">
                    <Grid item>
                      <Button
                        style={{ width: "98%%", padding: "0 8px" }}
                        onClick={() => navigate("/contacts")}
                      >
                        <Box width="98px">
                          <Img fluid={data.pcContact.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Hidden>

          <Hidden lgUp>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "46px",
                width: "87%",
                margin: "auto",
              }}
            >
              <div>
                <Button onClick={() => setOpen(!isOpen)}>
                  <Box width="20px">
                    <Img fluid={data.spMenu.childImageSharp.fluid} />
                  </Box>
                </Button>
              </div>

              <LogoPosition>
                <a href="/#top">
                  <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                </a>
              </LogoPosition>
              <Button onClick={() => navigate("/contacts")}>
                <Box width="86px">
                  <Img fluid={data.spContact.childImageSharp.fluid} />
                </Box>
              </Button>
            </div>
          </Hidden>
        </Box>
        <Box
          style={
            isOpen == true
              ? {
                  backgroundColor: "#1a1a1a",
                  color: "#e8ded3",
                  position: "fixed",
                  zIndex: "999",
                  width: "100vw",
                  height: "100vh",
                }
              : {
                  display: "none",
                }
          }
        >
          <Box
            style={{
              backgroundColor: "#1a1a1a",
              color: "#e8ded3",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "46px",
                width: "87%",
                margin: "auto",
              }}
            >
              <div>
                <Button onClick={() => setOpen(!isOpen)}>
                  <Box width="20px">
                    <Img fluid={data.batu.childImageSharp.fluid} />
                  </Box>
                </Button>
              </div>
              <LogoPosition>
                <a href="/#top">
                  <Img fluid={data.placeholderImage.childImageSharp.fluid} />
                </a>
              </LogoPosition>
            </div>
          </Box>
          <Box width="100%" height="100%" bgcolor="#000000" pt={4}>
            <div
              style={{
                width: "45%",
                height: "80%",
                maxWidth: "800px",
                margin: "auto",
              }}
            >
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="flex-start"
                style={{ height: "100%" }}
              >
                <div style={{ width: "100%" }}>
                  {/* const MenuFont = styled(WrapTypo)`
    font-family: "Brooklyn";
    color: #e8ded3;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 200%;
    font-size: 22px;
  ` */}
                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      to="/#about"
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      onClick={() => setOpen(!isOpen)}
                    >
                      ABOUT
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      to="/#art"
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      onClick={() => setOpen(!isOpen)}
                    >
                      ART&amp;ROOMS
                      <br />
                      （PRICE）
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      to="/#floor"
                      onClick={() => setOpen(!isOpen)}
                    >
                      FLOOR
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      to="/#service"
                      onClick={() => setOpen(!isOpen)}
                    >
                      SERVICE
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      to="/#event"
                      onClick={() => setOpen(!isOpen)}
                    >
                      EVENT
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      to="/#news"
                      onClick={() => setOpen(!isOpen)}
                    >
                      NEWS
                    </Link>
                  </MenuFont>

                  <MenuFont style={{ textAlign: "center", width: "100%" }}>
                    <Link
                      to="/#access"
                      style={{ fontFamily: "Noto Sans JP,sans-serif" }}
                      onClick={() => setOpen(!isOpen)}
                    >
                      ACCESS
                    </Link>
                  </MenuFont>
                </div>

                <Grid container spacing={3}>
                  <Button
                    style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                    onClick={() => navigate("/contacts")}
                  >
                    <Box style={{ width: "100%" }}>
                      <Img fluid={data.spContact.childImageSharp.fluid} />
                    </Box>
                  </Button>
                </Grid>

                <Grid container spacing={3}>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "135px",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <div style={{ width: "16%" }}>
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        to="https://twitter.com/A_YOTSUYA"
                      >
                        <Img fluid={data.twitter.childImageSharp.fluid} />
                      </Link>
                    </div>
                    <div style={{ width: "7%" }}>
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        to="https://www.facebook.com/a.yotsuya/"
                      >
                        <Img fluid={data.facebook.childImageSharp.fluid} />
                      </Link>
                    </div>
                    <div style={{ width: "13%" }}>
                      <Link
                        rel="noreferrer"
                        target="_blank"
                        to="https://www.instagram.com/a_yotsuya/"
                      >
                        <Img fluid={data.instagram.childImageSharp.fluid} />
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Image
