import React, { useState, useEffect } from "react"
import { Box, Grid, Hidden, Button } from "@material-ui/core"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import Modal from "../components/Modal"

const fetchProductData = async () => {
  const fetchProduct = await fetch(
    "https://yotsuya-office.microcms.io/api/v1/room?limit=40",
    { headers: { "X-API-KEY": "5bc7ab07-9927-425b-a212-51abab8b1964" } }
  )
  try {
    return await fetchProduct.json()
  } catch (e) {}
}

const addPx = number => {
  return String(number) + "px"
}

const ChoiceArt = props => {
  const [artList, setArtList] = useState([])
  const productArray = []
  const [choiceArt, setChoiceArt] = useState(-1)
  useEffect(() => {
    fetchProductData()
      .then(data => {
        data &&
          data.contents &&
          data.contents.map(c => {
            productArray.push(c)
          })
        setArtList([...productArray])
      })
      .catch(e => {
        console.log({ e })
      })
  }, [setArtList])
  const PcImgWidth = 200
  const PcTbImgWidth = 200
  const TbImgWidth = 150
  const SpImgWidth = 100

  return (
    <>
      {/* <Modal data={artList[choiceArt]} setChoiceArt={setChoiceArt} /> */}
      <Hidden mdDown>
        <Box width="1000px" margin="auto" display="flex" flexWrap="wrap">
          {artList.map((c, i) => (
            <Button
              style={{ margin: 0, padding: 0, borderRadius: "0px" }}
              onClick={() => {
                navigate("/contactroom?room=" + c.id, {
                  state: { roomId: c.id },
                })
              }}
            >
              <img
                width={addPx(PcImgWidth)}
                height={addPx(PcImgWidth)}
                style={{
                  objectFit: "cover",
                  margin: 0,
                  padding: 0,
                  display: "block",
                }}
                src={c.thumbnail && c.thumbnail.url}
              />
            </Button>
          ))}
        </Box>
      </Hidden>
      <Hidden smDown lgUp>
        <Box width="800px" margin="auto" display="flex" flexWrap="wrap">
          {artList.map((c, i) => (
            <Button
              style={{ margin: 0, padding: 0, borderRadius: "0px" }}
              onClick={() => {
                navigate("/contactroom?room=" + c.id, {
                  state: { roomId: c.id },
                })
              }}
            >
              <img
                width={addPx(PcTbImgWidth)}
                height={addPx(PcTbImgWidth)}
                style={{
                  objectFit: "cover",
                  margin: 0,
                  padding: 0,
                  display: "block",
                }}
                src={c.thumbnail && c.thumbnail.url}
              />
            </Button>
          ))}
        </Box>
      </Hidden>
      <Hidden mdUp xsDown>
        <Box width="600px" margin="auto" display="flex" flexWrap="wrap">
          {artList.map((c, i) => (
            <Button
              style={{ margin: 0, padding: 0, borderRadius: "0px" }}
              onClick={() => {
                navigate("/contactroom?room=" + c.id, {
                  state: { roomId: c.id },
                })
              }}
            >
              <img
                width={addPx(TbImgWidth)}
                height={addPx(TbImgWidth)}
                style={{
                  objectFit: "cover",
                  margin: 0,
                  padding: 0,
                  display: "block",
                }}
                src={c.thumbnail && c.thumbnail.url}
              />
            </Button>
          ))}
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box width="300px" maxWidth="800px" margin="auto">
          <Grid container spacing={0}>
            {artList.map((c, i) => (
              <Button
                style={{ margin: 0, padding: 0, borderRadius: "0px" }}
                onClick={() => {
                  navigate("/contactroom?room=" + c.id, {
                    state: { roomId: c.id },
                  })
                }}
              >
                <img
                  width={addPx(SpImgWidth)}
                  height={addPx(SpImgWidth)}
                  style={{
                    objectFit: "cover",
                    margin: 0,
                    padding: 0,
                    display: "block",
                  }}
                  src={c.thumbnail && c.thumbnail.url}
                />
              </Button>
            ))}
          </Grid>
        </Box>
      </Hidden>
    </>
  )
}

export default ChoiceArt
