import React, { useState, useEffect } from "react"
import { Box, Grid, Hidden, Button, Modal } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { width } from "@material-ui/system"
import WrapTypo from "../components/WrapTypo"
import Twitter from "../images/svg/D_Twitter.svg"
import Facebook from "../images/svg/D_Facebook.svg"
import Instgram from "../images/svg/D_Instagram.svg"
import WTwitter from "../images/svg/w_twitter.svg"
import WFacebook from "../images/svg/w_facebook.svg"
import WInstgram from "../images/svg/w_instagram.svg"
import Close from "../images/svg/close.svg"

const RootBox = styled(Box)`
  @supports (-webkit-touch-callout: none) {
    body {
      /* Safari用のハック */
      height: -webkit-fill-available;
    }
  }
`

const IconBox = styled(Box)``

const DataModal = props => {
  const data = props.data
  //   artist: "KASUMI"
  //   bottom: {
  //     url: "https://images.microcms-assets.io/protected/ap-nor…f5a8d8/service/yotsuya-office/media/image%203.jpg"
  //   }
  //   concept: "街並みの中に生きる、『A girl 』がテーマ。四ツ谷の街や、当ビルのテーマとなっているNYの街並みや、どこかのきらめくビル群や明かりを連想させるオブジェをちりばめています。"
  //   createdAt: "2020-11-04T15:02:19.749Z"
  //   deskNum: 6
  //   facebook: "https://facebook.com"
  //   id: "do-s9ainml"
  //   instagram: "https://instagram.test.com"
  //   left: {
  //     url: "https://images.microcms-assets.io/protected/ap-nor…8d8/service/yotsuya-office/media/Mask%20Group.jpg"
  //   }
  //   name: "No.206"
  //   profile: "ビジュアルグラフィックアーティスト 1993年福岡生まれ。現在は東京を中心に活動。企業から壁画制作やイベントやライブペイント出演、個人クライアントなどから絵画などを受注制作。 リアルイラストレーションからデフォルメ、デザインまで見た物を応用して新しく作り出し、美しさや魅力的な理想を追い求める。ARTBATTLE TOKYO 2019 Champion"
  //   publishedAt: "2020-11-04T15:02:19.749Z"
  //   right: {
  //     url: "https://images.microcms-assets.io/protected/ap-nor…f5a8d8/service/yotsuya-office/media/image%201.jpg"
  //   }
  //   thumbnail: {
  //     url: "https://images.microcms-assets.io/protected/ap-nor…8d8/service/yotsuya-office/media/Mask%20Group.jpg"
  //   }
  //   top: {
  //     url: "https://images.microcms-assets.io/protected/ap-nor…f5a8d8/service/yotsuya-office/media/image%202.jpg"
  //   }
  //   twitter: "https://twitter.test.com"
  //   updatedAt: "2020-11-05T07:08:39.501Z"
  //   __proto__: Object

  return (
    <>
      {data && data.top ? (
        <Modal
          open={() => {
            return data && data.top
          }}
          onClose={() => props.setChoiceArt(-1)}
          disableAutoFocus={true}
          style={{
            border: "none",
            width: "100%",
            overflow: "scroll",
            outline: "none",
          }}
        >
          <Box
            mt={10}
            mb={16}
            width="90%"
            maxWidth="800px"
            margin="auto"
            bgcolor="#1A1A1A"
            py={5}
            overflow="scroll"
            zIndex={999}
            outline="none"
            style={{ outline: "none" }}
          >
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginBottom: "16px" }}
            >
              <Grid item xs={8} sm={5}>
                <WrapTypo ff="Brooklyn" variant="h6" color="#fff" mt={"0px"}>
                  Room : {data.name}
                </WrapTypo>
                <WrapTypo ff="Brooklyn" variant="h6" color="#fff" mt={"0px"}>
                  Artist : {data.artist}
                </WrapTypo>
              </Grid>
              <Grid item xs={2} sm={3} alignItems="flex-start">
                <Hidden smUp>
                  <Button
                    style={{
                      textAlign: "right",
                      right: "-16px",
                      top: "-16px",
                    }}
                    onClick={() => props.setChoiceArt(-1)}
                  >
                    <Close />
                  </Button>
                </Hidden>
              </Grid>
              <Grid item xs={10} sm={2}>
                <Hidden smUp>
                  <Button
                    style={{
                      textAlign: "center",
                      background: "red",
                      borderRadius: "15px",
                      textAlign: "right",
                      padding: "1px 18px 0px",
                      marignTop: "16px",
                    }}
                    alignContent="center"
                    rel="noreferrer"
                    target="_blank"
                    href={"/contacts?room=" + data.name}
                  >
                    <WrapTypo
                      mt={"0px"}
                      ff="Brooklyn"
                      style={{ textAlign: "center", fontSize: "13px" }}
                    >
                      CONTACT
                    </WrapTypo>
                  </Button>
                </Hidden>
                <Hidden xsDown>
                  <Box
                    width="100%"
                    textAlign="right"
                    position="relative"
                    height="60px"
                  >
                    <Button
                      style={{
                        textAlign: "right",
                        position: "absolute",
                        right: "-16px",
                        top: 0,
                        width: "10px",
                        width: "10px",
                      }}
                      onClick={() => props.setChoiceArt(-1)}
                    >
                      <Close />
                    </Button>

                    <Button
                      style={{
                        textAlign: "center",
                        background: "red",
                        borderRadius: "15px",
                        textAlign: "right",
                        position: "absolute",
                        bottom: "3px",
                        right: 0,
                        padding: "2px 24px 0px",
                      }}
                      alignContent="center"
                      href={"/contacts?room=" + data.name}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <WrapTypo
                        mt={"0px"}
                        ff="Brooklyn"
                        style={{ textAlign: "center", fontSize: "13px" }}
                      >
                        CONTACT
                      </WrapTypo>
                    </Button>
                  </Box>
                </Hidden>
              </Grid>
            </Grid>

            <Grid container justify="center" alignItems="center">
              <Grid item xs={10}>
                <img src={data.top.url} width="100%" />
              </Grid>
              <Grid item xs={5} style={{ marginTop: "8px" }}>
                <img src={data.left.url} width="100%" />
              </Grid>
              <Grid item xs={5} style={{ marginTop: "8px" }}>
                <img src={data.right.url} width="100%" />
              </Grid>
              <Grid item xs={10} style={{ marginTop: "8px" }}>
                <img src={data.bottom.url} width="100%" />
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={5} style={{ margin: "32px 0 0" }}>
                <WrapTypo
                  color="#fff"
                  variant="h5"
                  mt={"0px"}
                  style={{ textAlign: "left" }}
                >
                  Artist
                </WrapTypo>
                <WrapTypo color="#fff" mt={"8px"} style={{ textAlign: "left" }}>
                  {data.artist}
                </WrapTypo>
              </Grid>
              <Grid item xs={5} style={{ margin: "32px 0 0" }}>
                <WrapTypo
                  color="#fff"
                  mt={"0px"}
                  variant="h5"
                  style={{ textAlign: "left" }}
                >
                  No. of Desks
                </WrapTypo>
                <WrapTypo color="#fff" mt={"8px"} style={{ textAlign: "left" }}>
                  {data.deskNum}
                </WrapTypo>
              </Grid>
              <Grid item xs={10}>
                <WrapTypo
                  varinat="h4"
                  color="#fff"
                  mt={"32px"}
                  variant="h5"
                  style={{ textAlign: "left" }}
                >
                  Concept
                </WrapTypo>
                <WrapTypo
                  color="#fff"
                  mt={"16px"}
                  style={{ textAlign: "left", fontSize: "14px" }}
                >
                  {data.concept}
                </WrapTypo>
              </Grid>
              <Grid item xs={10}>
                <WrapTypo
                  variant="h5"
                  color="#fff"
                  mt={"32px"}
                  style={{ textAlign: "left" }}
                >
                  Profile
                </WrapTypo>
                <WrapTypo
                  color="#fff"
                  mt={"16px"}
                  fw={200}
                  style={{ textAlign: "left", fontSize: "14px" }}
                >
                  {data.profile}
                </WrapTypo>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Hidden mdUp>
                <Grid item xs={6} textAlign="center">
                  <Icons data={data} />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid item xs={6} sm={3}>
                  <Icons data={data} />
                </Grid>
                <Grid item xs={4} sm={7}></Grid>
              </Hidden>
            </Grid>
            <Grid container justify="center">
              <Grid item xs={10} style={{ textAlign: "center" }}>
                <WrapTypo color="#fff" fw="300" style={{ fontSize: "12px" }}>
                  価格についてはお問い合わせ下さい
                </WrapTypo>
                <Button
                  href={"/contacts?room=" + data.name}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    margin: "9px 0 16px",
                    padding: "24px 70px",
                    border: "1px solid #fff",
                    borderRadius: "0px",
                  }}
                >
                  <WrapTypo color="#fff" mt={"0px"}>
                    CONTACT US
                  </WrapTypo>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : (
          <></>
        )}
    </>
  )
}

const Icons = props => {
  const data = props.data
  return (
    <Grid container justify="flex-start" style={{ marginTop: "64px" }}>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        {data.twitter ? (
          <Link to={data.twitter}>
            <IconBox>
              <WTwitter />
            </IconBox>
          </Link>
        ) : (
            <IconBox>
              <Twitter />
            </IconBox>
          )}
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        {data.instagram ? (
          <Link to={data.instagram}>
            <IconBox>
              <WInstgram />
            </IconBox>
          </Link>
        ) : (
            <IconBox>
              <Instgram />
            </IconBox>
          )}
      </Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        {data.facebook ? (
          <Link to={data.facebook}>
            <WFacebook />
          </Link>
        ) : (
            <IconBox>
              <Facebook />
            </IconBox>
          )}
      </Grid>
    </Grid>
  )
}

export default DataModal
